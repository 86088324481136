import { Badge, Box, Flex, Grid, Heading, HStack, Icon, IconButton, Skeleton, Stack, Text } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import { getDashboardOnboardingStepStatus, ONBOARDING_STATE_KEY } from 'apis/dashboard-apis';
import { usePaywall } from 'app/acl/paywall';
import { ArrowRight, MailIcon } from 'components/icons';
import { CloseButton } from 'components/ui/close-button';
import { useOrg } from 'hooks/useOrg';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { PhysicalMailStatus } from 'types/shared-types';

import { FilingIcon, RegisterIcon } from './dashboard-icons';

export const DashboardAutomation = () => {
  const { orgId, isTest } = useOrg();
  const { isPaidUser } = usePaywall();
  const [isVisible, setIsVisible] = useState(() => {
    const isDismissed = localStorage.getItem(`automation-dismissed-${orgId}`);
    return isDismissed === null ? true : !isDismissed;
  });

  const { data: onboardingData, isPending: isStepStatusLoading } = useQuery({
    queryKey: [ONBOARDING_STATE_KEY, 'steps', 'status', orgId],
    queryFn: async () => {
      const response = await getDashboardOnboardingStepStatus(orgId);
      return (
        response?.data ?? {
          physical_mail_address_status: null,
          auto_file: false,
          auto_register: false,
        }
      );
    },
    enabled: isPaidUser && isVisible,
  });

  useEffect(() => {
    const isDismissed = localStorage.getItem(`automation-dismissed-${orgId}`);
    setIsVisible(isDismissed === null ? true : !isDismissed);
  }, [orgId]);

  const handleClose = () => {
    localStorage.setItem(`automation-dismissed-${orgId}`, 'true');
    setIsVisible(false);
  };

  const navigate = useNavigate();

  const data = [
    {
      icon: <MailIcon />,
      title: 'Physical Mail',
      description: 'Set up a free physical mail address to have your mail digitized and managed.',
      isCompleted:
        onboardingData?.physical_mail_address_status === PhysicalMailStatus.verify ||
        onboardingData?.physical_mail_address_status === PhysicalMailStatus.complete,
      href: '/configurations/kintsugimail',
    },
    {
      icon: <FilingIcon />,
      title: 'Auto File',
      description: 'Automatically approve filings and never miss a due date',
      isCompleted: onboardingData?.auto_file,
      href: '/configurations/settings',
    },
    {
      icon: <RegisterIcon />,
      title: 'Auto Register',
      description: 'Automatically approve registrations and never miss a due date',
      isCompleted: onboardingData?.auto_register,
      href: '/configurations/settings',
    },
  ];

  const isAllCompleted = data.every(item => item.isCompleted);

  if (isAllCompleted || !isVisible || !isPaidUser || isTest) {
    return null;
  }

  return (
    <Skeleton loading={isStepStatusLoading}>
      <Box p={4} borderWidth="1px" borderColor="gray.100" borderRadius="4px">
        <Flex justify="space-between" align="center" mb={2}>
          <Heading size="sm" fontWeight={500} color="gray.800" fontSize={'18px'}>
            Setup Automation
          </Heading>
          <CloseButton
            size="md"
            onClick={() => {
              handleClose();
            }}
          />
        </Flex>

        <Grid
          templateColumns={{
            base: '1fr',
            md: 'repeat(3, 1fr)',
          }}
          gap={4}
        >
          {data.map((item, index) => (
            <Flex
              borderWidth="1px"
              borderColor={item.isCompleted ? 'gray.50' : 'gray.100'}
              borderRadius="4px"
              bg="white"
              py={4}
              pl={4}
              pr={2}
              key={index}
              align={'center'}
              justifyContent={'space-between'}
              onClick={() => {
                if (!item.isCompleted) {
                  navigate(item.href);
                }
              }}
              cursor={item.isCompleted ? 'default' : 'pointer'}
            >
              <HStack>
                <IconButton
                  rounded="full"
                  aria-label="icon"
                  bg={'secondary.50'}
                  _hover={{ bg: 'secondary.50' }}
                  color={'secondary.500'}
                  boxSize={'42px'}
                  mr={3}
                >
                  <Icon size="md">{item.icon}</Icon>
                </IconButton>
                <Stack gap={1}>
                  <HStack>
                    <Text fontWeight={500} color="gray.800" fontSize={'15px'}>
                      {item.title}
                    </Text>
                    {item.isCompleted && (
                      <Badge colorPalette={'green'} fontSize={'12px'} fontWeight={400} height={'5'}>
                        Completed
                      </Badge>
                    )}
                  </HStack>
                  <Text fontSize={'15px'} color="gray.500">
                    {item.description}
                  </Text>
                </Stack>
              </HStack>

              {!item.isCompleted && (
                <IconButton aria-label="icon" variant="transparent-with-icon">
                  <ArrowRight size="md" />
                </IconButton>
              )}
            </Flex>
          ))}
        </Grid>
      </Box>
    </Skeleton>
  );
};
