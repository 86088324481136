import { Box, BoxProps, Flex, GridItem, Skeleton, Text, useMediaQuery } from '@chakra-ui/react';
import { ApexOptions } from 'apexcharts';
import ApexChart from 'react-apexcharts';

export type KChartProps = {
  type: 'line' | 'bar';
  height: BoxProps['height'];
  heading: string;
  series: ApexOptions['series'];
  options: Omit<ApexOptions, 'series'>;
  isLoading?: boolean;
  format?: 'currency' | 'number';
  styles?: Omit<BoxProps, 'children' | 'as' | 'h' | 'height'>;
  headerRight?: React.ReactNode;
};

export const Chart = ({ type, height, heading, series, options, isLoading, styles = {}, headerRight }: KChartProps) => {
  const [isLargeScreen] = useMediaQuery(['(min-width: 1915px)'], { ssr: false });

  if (isLoading) {
    return <Skeleton h={height} />;
  }

  const maxValue = Math.max(...(series as any[]).flatMap(s => s?.data || []));
  const categoriesLength = options.xaxis?.categories?.length || 0;
  const emptyCategories = options.xaxis?.categories?.length === 0;

  let chartHeight = '310px';
  if (type === 'bar') {
    chartHeight = emptyCategories ? `310px` : `${Math.max(Math.max(categoriesLength, 1) * 25, 310)}px`;
  }

  const barChartWidth = `${Math.max(maxValue / 1000000, 800)}px`;

  const getWidth = () => {
    if (!isLargeScreen) {
      return barChartWidth;
    }
    return 'auto';
  };

  return (
    <GridItem
      border={'1px solid var(--gray-50, #EFEFF3)'}
      borderRadius={4}
      p={4}
      h={height}
      zIndex={0}
      {...styles}
      overflowX={type === 'bar' && !emptyCategories ? 'auto' : 'hidden'}
      overflowY={'hidden'}
    >
      <Flex justifyContent={'space-between'}>
        <Text fontSize={'lg'} fontWeight={'bold'} mb={2}>
          {heading}
        </Text>
        {headerRight}
      </Flex>
      <Box
        height={type === 'bar' ? 'calc(100% - 15px)' : 'auto'}
        overflowY={type === 'bar' ? 'auto' : 'visible'}
        width={type === 'bar' ? getWidth() : 'auto'}
        fontSize="sm"
        color="gray.800"
      >
        <ApexChart
          type={type}
          height={emptyCategories ? '320px' : chartHeight}
          series={series}
          options={{
            ...options,
            chart: {
              ...options.chart,
              parentHeightOffset: 0,
            },
          }}
        />
      </Box>
    </GridItem>
  );
};
