import { CountryOptionsType } from 'types/address';
import { Address, AddressInstance } from 'types/shared-types';

type AddressTransformers = {
  [key: string]: (value: string) => string;
};

export const generateAddressString = (
  address: Address | AddressInstance,
  transformers: AddressTransformers = {}
): string => {
  const parts: (string | undefined)[] = [
    getTransformedValue('phone', address, transformers),
    getTransformedValue('street_1', address, transformers),
    getTransformedValue('street_2', address, transformers),
    getTransformedValue('city', address, transformers),
    getTransformedValue('county', address, transformers),
    getTransformedValue('state', address, transformers),
    getTransformedValue('postal_code', address, transformers),
    getTransformedValue('country', address, transformers),
  ];

  // Filter out any empty parts
  const addressString = parts.filter(part => part).join(', ');

  return addressString;
};

// Helper function to handle the transformation logic
function getTransformedValue(
  field: keyof Address,
  address: Address | AddressInstance,
  transformers: AddressTransformers
): string | undefined {
  const value = address[field] ?? '';
  if (transformers[field]) {
    return transformers[field](value);
  }
  return address[field] ?? undefined;
}

export const getSortedCountries = (countries: CountryOptionsType[]) => {
  if (!countries) return countries;

  const topCountries = ['US', 'CA']; // List of countries to prioritize (US and Canada)

  // Separate the top countries from the list
  const prioritizedCountries = countries.filter(country => topCountries.includes(country.value));

  // Sort the top countries alphabetically by label
  const sortedTopCountries = prioritizedCountries.sort((a, b) => a.label.localeCompare(b.label));

  // Sort the remaining countries alphabetically by label
  const remainingCountries = countries
    .filter(({ value }) => !topCountries.includes(value))
    .sort((a, b) => a.label.localeCompare(b.label));

  // Combine the prioritized countries at the top with the sorted remaining countries
  return [...sortedTopCountries, ...remainingCountries];
};
