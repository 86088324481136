import { ApiResponse, TableFilter, TransactionInstance } from 'types/shared-types';

import api from './api';

export const transactionsQueryKeys = {
  base: ['transactions'] as const,
  transactions: () => [...transactionsQueryKeys.base, 'transactions'] as const,
  transactionList: () => [...transactionsQueryKeys.transactions(), 'list'] as const,
};

const getTransactions = (orgId: string, params?: TableFilter) => {
  return api.get<ApiResponse<TransactionInstance>>(`/v1/transactions`, {
    method: 'GET',
    headers: {
      'x-organization-id': orgId,
    },
    params,
  });
};

const TRANSACTION_STATE_KEY = 'transactions';
export { getTransactions, TRANSACTION_STATE_KEY };
