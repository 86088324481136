import { Alert, AlertProps } from 'components/ui/alert';
import { MdInfo } from 'react-icons/md';

type AlertBannerProps = {
  message: string;
} & AlertProps;

const AlertBanner = ({ message = '', ...rest }: AlertBannerProps) => {
  return (
    <Alert
      status="info"
      alignItems={{ base: 'center', md: 'center', sm: 'flex-start' }}
      overflow={{ base: 'hidden', md: 'hidden', sm: 'visible' }}
      icon={<MdInfo />}
      {...rest}
    >
      {message}
    </Alert>
  );
};

export default AlertBanner;
