import { OrganizationRead } from '_api-client';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import {
  ApiKeyCreateResponse,
  APIKeyRequest,
  APIKeysRequest,
  APIKeysResponse,
  InvitesResponse,
  PartnerOrganizationsResponse,
  UsersRequest,
  UsersResponse,
} from 'types/organizations';
import {
  ApiResponse,
  AutoFileAutoRegisterInstance,
  AutoFileAutoRegisterUpdateRequest,
  OrgDetailsInstance,
  OrgInstance,
  TableFilter,
  UserInviteRequest,
  UserRoleUpdateRequest,
} from 'types/shared-types';

import api from './api';

// TODO: Please follow the naming convention for the keys like ORGANIZATION_SERVICE_QUERY_KEYS and remove this comment
export const USERS_STATE_KEY = 'users';
export const INVITES_STATE_KEY = 'invites';
export const USERS_AND_INVITES_STATE_KEY = 'users-and-invites';
export const API_KEYS_STATE = 'apiKeys';

export const PHYSICAL_MAIL = 'physicalMail'; // TODO: Please follow the naming convention for the keys like ORGANIZATION_SERVICE_QUERY_KEYS and remove this comment

export const ORGANIZATION_SERVICE_QUERY_KEYS = {
  base: ['organization'] as const,
  details: (orgId: string) => [...ORGANIZATION_SERVICE_QUERY_KEYS.base, orgId, 'details'] as const,
  list: (orgId: string) => [...ORGANIZATION_SERVICE_QUERY_KEYS.details(orgId), 'list'] as const,
  autoFileAutoRegister: (orgId: string) =>
    [...ORGANIZATION_SERVICE_QUERY_KEYS.details(orgId), 'auto_file', 'auto_register'] as const,
};

export const getAllOrgs = () => {
  return api.get('/v1/organizations/');
};

const getOrganizationById = (orgId: string) =>
  api.get<OrgInstance>(`/v1/organizations/${orgId}`, {
    headers: {
      'x-organization-id': orgId,
    },
  });

const getAllOrgsByPaged = async ({ size }: { size: number }) => {
  let totalPages = 1;
  let results: OrganizationRead[] = [];
  let page = 1;

  while (page <= totalPages) {
    const params = {
      page: page,
      size: size,
    };
    try {
      const { data } = await api.get(`/v1/organizations/`, {
        params: {
          ...params,
        },
      });
      results = [...results, ...data.items];
      totalPages = data.pages;
      page++;
    } catch (error) {
      return results;
    }
  }
  return results;
};

const getOrgs = (orgId: string, page: number, size: number, query: string) => {
  let params = {
    page,
    size,
  } as Record<string, any>;
  if (query) {
    params = {
      ...params,
      page: 1, // override the page size for search to ensure the data is not under looked.
      query,
    };
  }

  if (orgId) {
    params = {
      ...params,
      selected_organization_id: orgId,
    };
  }
  return api.get(`/v1/organizations/`, {
    params,
  });
};

export const getOrganizations = (orgId: string, params?: TableFilter) => {
  return api.get<ApiResponse<OrganizationRead>>(`/v1/organizations/`, {
    headers: {
      'x-organization-id': orgId,
    },
    params,
  });
};

const getOrgDetails = (orgId: string) => {
  return api.get<OrgDetailsInstance>(`/v1/organization_details`, {
    headers: {
      'x-organization-id': orgId,
    },
  });
};

const useOrgDetailsQuery = (orgId: string, options?: Partial<UseQueryOptions<OrgDetailsInstance, unknown>>) =>
  useQuery({
    queryKey: [...ORGANIZATION_SERVICE_QUERY_KEYS.details(orgId)],
    queryFn: async () => {
      const { data } = await getOrgDetails(orgId);
      return data;
    },
    ...options,
  });

const useGetOrgDetailsListQuery = (orgId: string, options?: Partial<UseQueryOptions<OrgDetailsInstance, unknown>>) =>
  // used this query to fetch the org details list for the org details page
  useQuery({
    queryKey: [...ORGANIZATION_SERVICE_QUERY_KEYS.list(orgId)],
    queryFn: async () => {
      const { data } = await getOrgDetails(orgId);
      return data;
    },
    ...options,
  });

const getAllOrganizations = (query: string) => {
  return api.get(`/v1/organizations/all`, {
    params: {
      search_query: query,
    },
  });
};

const addOrUpdateBusinessDetails = (orgId: string, payload: any) => {
  return api.put(`/v1/organization_details/business_details/`, payload, {
    headers: {
      'x-organization-id': orgId,
    },
  });
};

const addOrUpdateBusinessAddress = (orgId: string, payload: any) => {
  return api.put(`/v1/organization_details/business_address/`, payload, {
    headers: {
      'x-organization-id': orgId,
    },
  });
};

const addOrUpdateBusinessInformation = (orgId: string, payload: any) => {
  return api.put(`/v1/organization_details/business_information/`, payload, {
    headers: {
      'x-organization-id': orgId,
    },
  });
};

const addOrUpdateBusinessContact = (orgId: string, payload: any) => {
  return api.put(`/v1/organization_details/business_contact/`, payload, {
    headers: {
      'x-organization-id': orgId,
    },
  });
};

const addOrUpdateBusinessOwners = (orgId: string, payload: any) => {
  return api.put(`/v1/organization_details/business_owners/`, payload, {
    headers: {
      'x-organization-id': orgId,
    },
  });
};

const archiveOrg = (orgId: string, orgName: string) => {
  return api.delete(`/v1/organizations/${orgId}/`, {
    headers: {
      'x-organization-id': orgId,
    },
    params: {
      organization_name: orgName,
    },
  });
};

const createTestOrganization = (orgName: string, isTest: boolean) => {
  return api.post(`/v1/organizations`, {
    name: orgName,
    is_test: isTest,
  });
};

const getAllEmailGroups = (orgId: string) => {
  return api.get(`/v1/email_groups`, {
    headers: {
      'x-organization-id': orgId,
    },
  });
};

const addEmailGroupMember = (orgId: string, payload: { email_group: string; member: string }) => {
  const { email_group, member } = payload;
  return api.post(`/v1/email_groups/`, null, {
    headers: {
      'x-organization-id': orgId,
    },
    params: {
      email_group: email_group,
      member: member,
    },
  });
};

const deleteEmailGroupMember = (orgId: string, emailGroup: string, member: string) => {
  return api.delete(`/v1/email_groups/`, {
    headers: {
      'x-organization-id': orgId,
    },
    params: {
      email_group: emailGroup,
      member: member,
    },
  });
};

const getPhysicalMailAddress = (orgId: string) => {
  return api.get(`/v1/organizations/physical_mail`, {
    headers: {
      'x-organization-id': orgId,
    },
  });
};

const getPhysicalMailExternalId = (orgId: string) => {
  return api.get(`/v1/organizations/physical_mail/external_id`, {
    headers: {
      'x-organization-id': orgId,
    },
  });
};

const getPhysicalMailOnboardingLink = (orgId: string) => {
  return api.get(`/v1/organizations/physical_mail/onboard`, {
    headers: {
      'x-organization-id': orgId,
    },
  });
};

const getPhysicalMailStatus = (orgId: string, payload: any) => {
  return api.post(`/v1/organizations/physical_mail/sync`, payload, {
    headers: {
      'x-organization-id': orgId,
    },
  });
};

const getUsersByOrgId = (orgId: string, { pageSize, pageNumber, includeOrgs = true, role }: UsersRequest) => {
  return api.get<UsersResponse>(`/v1/organizations/users`, {
    headers: {
      'x-organization-id': orgId,
    },
    params: {
      page_size: pageSize,
      page_number: pageNumber,
      include_orgs: includeOrgs,
      role,
    },
  });
};

const getInvitesByOrgId = (orgId: string, { pageSize, pageNumber }: UsersRequest) => {
  return api.get<InvitesResponse>(`/v1/organizations/users/invites`, {
    headers: {
      'x-organization-id': orgId,
    },
    params: {
      page_size: pageSize,
      page_number: pageNumber,
    },
  });
};

const inviteUserToOrg = (orgId: string, payload: UserInviteRequest) => {
  return api.post<{ message: string }>(`/v1/organizations/users/invites`, payload, {
    headers: {
      'x-organization-id': orgId,
    },
  });
};

const updateUserRole = (orgId: string, { userId, role }: UserRoleUpdateRequest) => {
  return api.put<{ message: string }>(
    `/v1/organizations/users/${userId}/role`,
    { role },
    {
      headers: {
        'x-organization-id': orgId,
      },
    }
  );
};

const removeUserFromOrg = (orgId: string, userId: string) => {
  return api.delete<{ message: string }>(`/v1/organizations/users/${userId}`, {
    headers: {
      'x-organization-id': orgId,
    },
  });
};

const getAutoFileAutoRegister = (orgId: string) => {
  return api.get<AutoFileAutoRegisterInstance>(`/v1/organization_details/auto_file/auto_register`, {
    headers: {
      'x-organization-id': orgId,
    },
  });
};

const useAutoFileAutoRegisterQuery = (
  orgId: string,
  options?: Partial<UseQueryOptions<AutoFileAutoRegisterInstance, unknown>>
) =>
  useQuery({
    queryKey: [...ORGANIZATION_SERVICE_QUERY_KEYS.autoFileAutoRegister(orgId)],
    queryFn: async () => {
      const { data } = await getAutoFileAutoRegister(orgId);
      return data;
    },
    ...options,
  });

const putAutoFileAutoRegister = (orgId: string, payload: AutoFileAutoRegisterUpdateRequest) => {
  return api.put<{ message: string }>(`/v1/organization_details/auto_file/auto_register`, payload, {
    headers: {
      'x-organization-id': orgId,
    },
  });
};

const getAPIKeysByOrgId = (orgId: string, { active = true, pageSize, pageNumber }: APIKeysRequest) => {
  return api.get<APIKeysResponse>(`/v1/organizations/api_keys`, {
    headers: {
      'x-organization-id': orgId,
    },
    params: {
      page_size: pageSize,
      page_number: pageNumber,
      active,
    },
  });
};

const createAPIKey = (orgId: string, payload: APIKeyRequest) => {
  return api.post<ApiKeyCreateResponse>(`/v1/organizations/api_keys`, payload, {
    headers: {
      'x-organization-id': orgId,
    },
  });
};

const updateAPIKey = (orgId: string, apiKeyId: string, payload: APIKeyRequest) => {
  return api.put(`/v1/organizations/api_keys/${apiKeyId}`, payload, {
    headers: {
      'x-organization-id': orgId,
    },
  });
};

const deleteAPIKey = (orgId: string, apiKeyId: string) => {
  return api.delete(`/v1/organizations/api_keys/${apiKeyId}`, {
    headers: {
      'x-organization-id': orgId,
    },
  });
};

const getPartnerOrganizations = (orgId: string, page: number, size: number, query: string) => {
  let params = {
    page,
    size,
  } as Record<string, any>;
  if (query) {
    params = {
      ...params,
      query,
    };
  }
  return api.get<PartnerOrganizationsResponse>(`/v1/partner/organizations`, {
    headers: {
      'x-organization-id': orgId,
    },
    params,
  });
};

export {
  addEmailGroupMember,
  addOrUpdateBusinessAddress,
  addOrUpdateBusinessContact,
  addOrUpdateBusinessDetails,
  addOrUpdateBusinessInformation,
  addOrUpdateBusinessOwners,
  archiveOrg,
  createAPIKey,
  createTestOrganization,
  deleteAPIKey,
  deleteEmailGroupMember,
  getAllEmailGroups,
  getAllOrganizations,
  getAllOrgsByPaged,
  getAPIKeysByOrgId,
  getAutoFileAutoRegister,
  getInvitesByOrgId,
  getOrganizationById,
  getOrgDetails,
  getOrgs,
  getPartnerOrganizations,
  getPhysicalMailAddress,
  getPhysicalMailExternalId,
  getPhysicalMailOnboardingLink,
  getPhysicalMailStatus,
  getUsersByOrgId,
  inviteUserToOrg,
  putAutoFileAutoRegister,
  removeUserFromOrg,
  updateAPIKey,
  updateUserRole,
  useAutoFileAutoRegisterQuery,
  useGetOrgDetailsListQuery,
  useOrgDetailsQuery,
};
