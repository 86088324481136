import { Flex, Heading, HStack, Icon, IconButton } from '@chakra-ui/react';
import { ArrowLeft } from 'components/icons';
import { APP_ROUTES, ModuleInfo, ROUTES_WITH_NO_NAV } from 'constants/app-constants';
import React, { useEffect, useState } from 'react';
import { matchRoutes, useLocation, useNavigate } from 'react-router-dom';

export const AppHeader = (): React.ReactNode => {
  const [activeModule, setActiveModule] = useState<ModuleInfo | undefined>({
    name: '',
    icon: null,
    href: '',
    label: '',
    minRole: 'Member',
  });
  const navigate = useNavigate();
  const location = useLocation();
  const pathname = location.pathname;
  const allRoutes = [...ROUTES_WITH_NO_NAV, ...APP_ROUTES];

  const goToPreviousPage = () => {
    navigate(-1);
  };

  useEffect(() => {
    setActiveModule(allRoutes[0]);
  }, []);

  useEffect(() => {
    const module = allRoutes.find(({ href }) => {
      const routes = [{ path: href }];
      return !!matchRoutes(routes, location);
    });

    setActiveModule(module);
  }, [pathname]);

  const labelsWithBackButton = [
    'Organizations',
    'Customer Details',
    'Transaction Details',
    'Cleanup Invalid Addresses',
    'Blank Addresses',
    'Integrations',
  ];

  return (
    <Flex data-testid="app-header" height={'4rem'} py={4} justify={'space-between'} alignItems="center">
      <HStack alignSelf={'center'}>
        {labelsWithBackButton.includes(activeModule?.label as string) && (
          <IconButton variant="transparent-with-icon" onClick={goToPreviousPage}>
            <Icon size="xl">
              <ArrowLeft />
            </Icon>
          </IconButton>
        )}
        <Heading size={'xl'} fontWeight={500} color={'gray.900'}>
          {activeModule?.label}
        </Heading>
      </HStack>
    </Flex>
  );
};

export default AppHeader;
