import { Flex, HStack, Icon, IconButton, Text } from '@chakra-ui/react';
import { LinkButton } from 'components/ui/link-button';
import { StatLabel, StatRoot } from 'components/ui/stat';

type TaxProcessStatsProps = {
  count: number;
  title: string;
  icon: React.ReactNode;
  buttonLabel?: string;
  onReviewClick?: () => void;
  reviewHref?: string;
  href?: string;
};

const TaxProcessStats = ({
  count,
  title,
  icon,
  buttonLabel = 'Review',
  onReviewClick,
  reviewHref,
  href,
}: TaxProcessStatsProps) => (
  <Flex align="center" justify="start" w="100%" gap={2} hidden={count == 0}>
    <IconButton variant="plain" bg="secondary.50" rounded="full" tabIndex={-1} aria-label="icon" size="md">
      <Icon w="6" h="6" color="secondary.500">
        {icon}
      </Icon>
    </IconButton>
    <StatRoot me="auto">
      <StatLabel fontSize="14px" color="gray.900" fontWeight="normal">
        <Text as="span" fontSize="16px" fontWeight="medium" mr={1}>
          {count}
        </Text>
        {title}
      </StatLabel>
    </StatRoot>
    <HStack>
      {reviewHref && (
        <LinkButton width={'120px'} href={reviewHref} variant={'outline'} colorPalette="blue" onClick={onReviewClick}>
          {buttonLabel}
        </LinkButton>
      )}

      {href && (
        <LinkButton width={'120px'} href={href} variant={'outline'} colorPalette="blue">
          Bulk Cleanup
        </LinkButton>
      )}
    </HStack>
  </Flex>
);

export default TaxProcessStats;
