import { ApexOptions } from 'apexcharts';
import { NumberStyleEnum } from 'types/utils';

import { orderByDate } from './dates';
import { formatNumber } from './utils';

export const MONTHS = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'] as const;

const BASE_LINE_CHART_OPTIONS: ApexOptions = {
  stroke: {
    width: 4,
  },
  chart: {
    width: '100%',
    height: '100%',
    toolbar: {
      show: true,
      tools: {
        download: false,
        selection: false,
        zoom: false,
        zoomin: true,
        zoomout: true,
        pan: true,
        reset: true || '<img src="/static/icons/reset.png" width="20">',
      },
    },
    zoom: {
      enabled: true,
      type: 'x',
      autoScaleYaxis: true,
      zoomedArea: {
        fill: {
          color: '#90CAF9',
          opacity: 0.4,
        },
        stroke: {
          color: '#0D47A1',
          opacity: 0.4,
          width: 1,
        },
      },
    },
  },
  colors: ['#FFB000'],
  fill: {
    colors: ['#FFB000'],
  },
  plotOptions: {
    bar: {
      columnWidth: '20%',
      distributed: false,
      rangeBarOverlap: true,
      rangeBarGroupRows: false,
      colors: {
        ranges: [
          {
            from: 0,
            to: 0,
            color: undefined,
          },
        ],
        backgroundBarColors: [],
        backgroundBarOpacity: 1,
        backgroundBarRadius: 0,
      },
    },
  },
  dataLabels: {
    enabled: false,
  },
  xaxis: {
    type: 'numeric',
    tickPlacement: 'on',
    tickAmount: 'dataPoints',
    axisBorder: {
      show: false,
    },
    axisTicks: {
      show: false,
    },
    labels: {
      style: {
        colors: ['#74798D'],
      },
      rotate: 0,
      rotateAlways: false,
    },
    tooltip: {
      enabled: false,
    },
  },
  yaxis: {
    show: true,
    labels: {
      show: true,
      style: {
        colors: ['#74798D'],
      },
    },
    axisTicks: {
      show: false,
    },
    tooltip: {
      enabled: false,
    },
  },

  grid: {
    borderColor: '#E2E8F0',
    strokeDashArray: 4,
    xaxis: {
      lines: {
        show: false,
      },
    },
  },
  markers: {
    size: 4,
    strokeColors: '#FFB000',
    hover: {
      size: 4,
    },
  },
};

const BASE_BAR_CHART_OPTIONS: ApexOptions = {
  chart: {
    type: 'bar',
    toolbar: {
      show: false,
    },
    zoom: {
      enabled: false,
    },
  },
  stroke: {
    curve: 'smooth',
    width: 1,
  },
  colors: ['#8EB6F8'],
  plotOptions: {
    bar: {
      borderRadius: 1,
      borderRadiusApplication: 'end',
      horizontal: true,
      barHeight: '70%',
      distributed: true,
    },
  },
  dataLabels: {
    enabled: false,
  },
  fill: {
    opacity: 1,
  },
};

const styles = `background: #262B47 !important;padding: 8px;color: #FFFFFF;font-weight:normal;font-size: 14px;`;

const customTooltip: ApexOptions['tooltip'] = {
  custom: ({ series, seriesIndex, dataPointIndex }: any) =>
    `<div class="custom-tooltip" style="${styles}">${series[seriesIndex][dataPointIndex]}</div>`,
};

const currencyTooltip: ApexOptions['tooltip'] = {
  custom: ({ series, seriesIndex, dataPointIndex }: any) =>
    `<div class="custom-tooltip" style="${styles}">${formatNumber(series[seriesIndex][dataPointIndex], {
      style: NumberStyleEnum.CURRENCY,
      currency: 'USD',
    })}</div>`,
};

export const getLineChartOptions = (
  type: 'category' | 'datetime' | 'numeric',
  hasCurrencyTooltip: boolean = true
): ApexOptions => {
  return {
    ...BASE_LINE_CHART_OPTIONS,
    xaxis: {
      ...BASE_LINE_CHART_OPTIONS.xaxis,
      type,
    },
    tooltip: hasCurrencyTooltip ? currencyTooltip : customTooltip,
    noData: { text: 'No data to display' },
  };
};

export const getBarChartOptions = (categories: string[], hasCurrencyTooltip: boolean = true): ApexOptions => {
  return {
    ...BASE_BAR_CHART_OPTIONS,
    chart: {
      type: 'bar',
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
        barHeight: '70%',
        distributed: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    colors: ['#8EB6F8'],
    xaxis: {
      categories,
      labels: {
        formatter: (val: string) => {
          if (hasCurrencyTooltip) {
            return formatNumber(parseFloat(val), {
              style: NumberStyleEnum.CURRENCY,
              currency: 'USD',
            });
          }
          return val;
        },
        show: true,
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      labels: {
        style: {
          fontSize: '12px',
        },
      },
    },
    grid: {
      xaxis: {
        lines: {
          show: false,
        },
      },
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
    legend: {
      show: false,
    },
    tooltip: hasCurrencyTooltip ? currencyTooltip : customTooltip,
    noData: {
      text: 'No data to display',
    },
  };
};

export const fillMissingChartData = (data: any[]) => {
  const missingDates = [];
  if (data && data.length === 0) {
    const maxDate = new Date().getFullYear();
    // showing the data for the last 5 years
    const minDate = maxDate - 5;
    for (let i = minDate; i <= maxDate; i++) {
      missingDates.push({ date: `${i}`, value: 0 });
    }
    return orderByDate(missingDates, 'date');
  }
  const dates = data.map(({ date }) => new Date(date).getFullYear());
  const maxDate = Math.max(...dates);
  // showing the data for the last 5 years
  const minDate = maxDate - 5;
  for (let i = minDate; i <= maxDate; i++) {
    if (!dates.includes(i)) {
      missingDates.push({ date: `${i}`, value: 0 });
    }
  }
  return orderByDate([...data, ...missingDates], 'date');
};

/**
 * Generates an array of years from current year minus the specified range
 * @param numberOfYears - How many years to generate
 * @returns Array of years as strings
 */
export const generateYearRange = (numberOfYears: number = 5): string[] => {
  const currentYear = new Date().getFullYear();
  return Array.from({ length: numberOfYears }, (_, i) => (currentYear - (numberOfYears - 1) + i).toString());
};

/**
 * Initializes data points for years with default values
 * @param years - Array of years
 * @param defaultValue - Default value for each year (defaults to 0)
 * @returns Array of data points with x (year) and y (value) properties
 */
export const initializeYearlyDataPoints = (years: string[], defaultValue: number = 0) => {
  return years.map(year => ({
    x: year,
    y: defaultValue,
  }));
};

/**
 * Initializes data points for months with default values
 * @param defaultValue - Default value for each month (defaults to 0)
 * @returns Array of data points with x (month) and y (value) properties
 */
export const initializeMonthlyDataPoints = (defaultValue: number = 0) => {
  return MONTHS.map(month => ({
    x: month,
    y: defaultValue,
  }));
};

/**
 * Finds the index of a month in the standard month array
 * @param month - Month abbreviation to find
 * @returns Index of the month or -1 if not found
 */
export const findMonthIndex = (month: string): number => {
  return MONTHS.findIndex(m => m === month);
};
