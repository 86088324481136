import { Box, Flex, Heading, Icon, Separator, Stack, Text } from '@chakra-ui/react';
import { LocationIcon, NoTaskIcon, ProductIcon } from 'components/icons';
import { Fragment } from 'react';
import { ProductStatusEnum } from 'schema/types-schema.d';
import { OrganizationTasksData } from 'types/charts';

import { AddressIcon, FilingIcon, RegisterIcon } from './dashboard-icons';
import TaxProcessStats from './tax-process-stats';

type TasksProps = {
  hasTransactions: boolean;
  data: OrganizationTasksData;
};

type Task = {
  count: number;
  title: string;
  icon: JSX.Element;
  href?: string;
  reviewHref?: string;
  onReviewClick?: () => void;
  buttonLabel?: string;
};

export const Tasks = ({
  hasTransactions,
  data: { registrations_to_finish, filings_to_approve, invalid_addresses, blank_addresses, pending_product },
}: TasksProps) => {
  const tasks: Task[] = [
    {
      count: registrations_to_finish,
      title: 'Registrations to Finish',
      icon: <RegisterIcon key="register" />,
      reviewHref: '/nexus',
    },
    {
      count: filings_to_approve,
      title: 'Filings to Approve',
      icon: <FilingIcon key="filing" />,
      reviewHref: '/filing',
    },
    {
      count: invalid_addresses,
      title: 'Invalid Addresses',
      icon: <AddressIcon key="address" />,
      href: '/invalid-addresses',
    },
    {
      count: blank_addresses,
      title: 'Blank Addresses',
      href: '/blank-addresses',
      icon: <LocationIcon key="location" color="primary.50" />,
      buttonLabel: 'Add',
    },
    {
      count: pending_product,
      title: 'Products to Approve',
      icon: <ProductIcon key="product" />,
      reviewHref: `/products?status__in=${ProductStatusEnum.PARTIALLY_APPROVED},${ProductStatusEnum.PENDING}`,
    },
  ];

  const visibleTasks = tasks.filter(task => task.count > 0);

  if (visibleTasks.length === 0) {
    return (
      <Box
        border={'1px solid var(--gray-100, #CFD0D8)'}
        borderRadius={'4px'}
        alignContent={{ base: 'center', md: 'center', sm: 'flex-end' }}
        pb={{ base: 'none', md: 'none', sm: 6 }}
        position={'relative'}
        height={'421px'}
      >
        <Text fontWeight={500} fontSize={'18px'} position={'absolute'} top={'19px'} left={4}>
          Tasks
        </Text>
        <Stack align={'center'} gap={1}>
          <Icon bgColor={'secondary.50'} height={'42px'} width={'42px'} borderRadius={'6px'} p={'9px'}>
            <NoTaskIcon />
          </Icon>

          <Text fontWeight={500} mt={4} fontSize="sm">
            No Tasks {!hasTransactions && 'Yet'}
          </Text>
          <Text fontSize="sm">
            {!hasTransactions ? 'Start with importing transactions and check back again.' : "You're all caught up."}
          </Text>
        </Stack>
      </Box>
    );
  }

  return (
    <Flex
      direction={'column'}
      border={'1px solid var(--gray-100, #CFD0D8)'}
      borderRadius={'4px'}
      px={4}
      overflowY={{ base: 'scroll', md: 'unset' }}
      height={'421px'}
      gap={4}
    >
      <Heading fontWeight={500} fontSize={'20px'} pt={4}>
        Tasks
      </Heading>

      {visibleTasks.map((task, index) => (
        <Fragment key={index}>
          <TaxProcessStats
            count={task.count}
            title={task.title}
            icon={task.icon}
            href={task.href}
            reviewHref={task.reviewHref}
            onReviewClick={task.onReviewClick}
            buttonLabel={task.buttonLabel}
          />
          {index < visibleTasks.length - 1 && <Separator hidden={task.count == 0} />}
        </Fragment>
      ))}
    </Flex>
  );
};
