import { useQuery } from '@tanstack/react-query';
import { getDashboardStats, getDashboardTasks } from 'apis/dashboard-apis';
import { useOrg } from 'hooks/useOrg';

export const useOrgTasksAndStats = () => {
  const { orgId } = useOrg();

  const { data: tasks, isLoading: isTasksLoading } = useQuery({
    queryKey: ['tasks', orgId],
    queryFn: async () => {
      const { data } = await getDashboardTasks(orgId);
      return data;
    },
    enabled: !!orgId,
    refetchOnWindowFocus: false,
  });

  const { data: stats, isLoading: isStatsLoading } = useQuery({
    queryKey: ['stats', orgId],
    queryFn: async () => {
      const { data } = await getDashboardStats(orgId);
      return data;
    },
    enabled: !!orgId,
    refetchOnWindowFocus: false,
  });

  return {
    tasks: tasks ?? {
      registrations_to_finish: 0,
      filings_to_approve: 0,
      pending_product: 0,
      invalid_addresses: 0,
      blank_addresses: 0,
    },
    stats: stats ?? {
      total_sales: 0,
      total_transactions: 0,
      outstanding_total_liability: 0,
      total_tax_remitted: 0,
    },
    isLoading: isTasksLoading || isStatsLoading,
  };
};
