import { createListCollection, HStack, Icon } from '@chakra-ui/react';
import { WorldIcon } from 'components/icons';
import { SelectContent, SelectItem, SelectRoot, SelectTrigger, SelectValueText } from 'components/ui/select';
import { CountryCodeEnum } from 'types/shared-types';

interface CountrySelectorProps {
  country?: CountryCodeEnum;
  onCountryChange: (country: CountryCodeEnum) => void;
  width?: string;
  size?: 'xs' | 'sm' | 'md' | 'lg';
  defaultValue?: CountryCodeEnum;
  className?: string;
}

export const CountrySelector = ({
  country,
  onCountryChange,
  width = '200px',
  size = 'sm',
  defaultValue = CountryCodeEnum.US,
  className,
}: CountrySelectorProps) => {
  const countries = createListCollection({
    items: [
      { label: 'United States', value: CountryCodeEnum.US },
      { label: 'Canada', value: CountryCodeEnum.CA },
    ],
  });

  return (
    <SelectRoot
      width={width}
      size={size}
      value={country ? [country] : undefined}
      onValueChange={({ value }) => onCountryChange(value[0] as CountryCodeEnum)}
      collection={countries}
      defaultValue={[defaultValue]}
      positioning={{ sameWidth: true }}
      className={className}
    >
      <SelectTrigger>
        <SelectValueText>
          {items => {
            if (!items?.length) return null;
            const { label } = items[0];
            return (
              <HStack>
                <Icon w="6" h="6">
                  <WorldIcon />
                </Icon>
                {label}
              </HStack>
            );
          }}
        </SelectValueText>
      </SelectTrigger>
      <SelectContent>
        {countries.items.map(item => (
          <SelectItem justifyContent="start" item={item} key={item.value}>
            <Icon w="6" h="6">
              <WorldIcon />
            </Icon>
            {item.label}
          </SelectItem>
        ))}
      </SelectContent>
    </SelectRoot>
  );
};
