import { Badge, Box, Flex, HStack, Skeleton, Stack, Text } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import { getNexus, NEXUS_STATE_KEY } from 'apis/dashboard-apis';
import { CountrySelector } from 'components/country-selector/country-selector';
import { NoExposureIcon } from 'components/icons';
import CanadaMap from 'components/maps/canada';
import MapLegend, { LegendItem } from 'components/maps/usa/map-legend';
import USMap from 'components/maps/usa/us-map';
import {
  DialogBackdrop,
  DialogBody,
  DialogCloseTrigger,
  DialogContent,
  DialogRoot,
  DialogTrigger,
} from 'components/ui/dialog';
import { useOrg } from 'hooks/useOrg';
import { useState } from 'react';
import { MdOutlineZoomIn } from 'react-icons/md';
import { useSearchParams } from 'react-router-dom';
import { CountryCodeEnum, NexusInstance, NexusStatus } from 'types/shared-types';

const DASHBOARD_LEGEND: LegendItem[] = [
  { label: 'Not exposed', color: 'rgba(255, 255, 255, 1)', bgColor: 'rgba(207, 208, 216, 1)' },
  { label: 'Approaching', color: '#FFE9C7', bgColor: 'transparent' },
  { label: 'Exposed', color: '#FED7D7', bgColor: 'transparent' },
  { label: 'Registering', color: '#D9E7FD', bgColor: 'transparent' },
  { label: 'Registered', color: '#C6F6D5', bgColor: 'transparent' },
];

const STATUS_COLOR_MAP: Record<NexusStatus, string> = {
  [NexusStatus.REGISTERED]: '#C6F6D5',
  [NexusStatus.PENDING_REGISTRATION]: '#D9E7FD',
  [NexusStatus.EXPOSED]: '#FED7D7',
  [NexusStatus.APPROACHING]: '#FFE9C7',
  [NexusStatus.NOT_EXPOSED]: '#FFFFFF',
};

type MapData = Record<string, { color: string }>;

const DashboardMap = () => {
  const { orgId } = useOrg();
  const [isFedNexusMet, setIsFedNexusMet] = useState<boolean>(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const country = searchParams.get('country');
  const [selectedCountry, setSelectedCountry] = useState<CountryCodeEnum>(
    country ? (country as CountryCodeEnum) : CountryCodeEnum.US
  );

  const {
    isPending: isMapLoading,
    data: mapData,
    isError,
  } = useQuery({
    queryKey: [NEXUS_STATE_KEY, 'map', orgId, selectedCountry],
    queryFn: async () => {
      const data = (await getNexus(orgId, selectedCountry)) || [];
      if (data.length === 0) {
        return [];
      }
      const res = (data as NexusInstance[])?.reduce((result: Record<string, object>, nexus: NexusInstance) => {
        const color = nexus.status ? STATUS_COLOR_MAP[nexus.status as NexusStatus] : '#FFFFFF';
        result[nexus.state_code] = { color };
        if (nexus.state_code === 'FD' && nexus.status === NexusStatus.EXPOSED) {
          setIsFedNexusMet(true);
        }
        return result;
      }, {});

      return res;
    },
  });

  if (isError) {
    console.error(isError);
    return null;
  }

  const handleCountryChange = (country: CountryCodeEnum) => {
    setSelectedCountry(country);
    setIsFedNexusMet(false);
    if (process.env.NODE_ENV !== 'test') {
      setSearchParams(params => {
        const newParams = new URLSearchParams(params);
        newParams.set('country', country);
        return newParams.toString();
      });
    }
  };

  if (!mapData && !isMapLoading) {
    return (
      <Flex border={'1px solid var(--gray-100, #CFD0D8)'} borderRadius={'4px'} height={'421px'} direction="column">
        <HStack justifyContent={'space-between'}>
          <Text fontWeight={500} fontSize={'18px'} p={4}>
            Exposure Map
          </Text>
          <Box mr={4}>
            <CountrySelector country={selectedCountry} onCountryChange={handleCountryChange} width="180px" />
          </Box>
        </HStack>
        <Flex flex={1} justify="center" align="center">
          <Stack align={'center'} gap={1}>
            <Badge bgColor={'secondary.50'} height={'42px'} width={'42px'} borderRadius={'6px'} p={'9px'}>
              <NoExposureIcon />
            </Badge>
            <Text fontWeight={500} mt={4}>
              No Exposure Data Yet
            </Text>
            <Text>Complete the onboarding to visualize your exposure</Text>
          </Stack>
        </Flex>
      </Flex>
    );
  }

  const getMapByCountry = (modalOpen?: boolean) => {
    if (selectedCountry === CountryCodeEnum.CA) {
      return <CanadaMap modalOpen={modalOpen} dataset={mapData as MapData} />;
    }
    return <USMap dataset={mapData as MapData} />;
  };

  const getFederalExposureMap = () => {
    return (
      <Flex
        position="absolute"
        bottom={16}
        right={6}
        w="200px"
        h="200px"
        bg="white"
        borderRadius="md"
        boxShadow="md"
        p={2}
        border="1px solid"
        borderColor="gray.100"
        zIndex={2}
        direction={'column'}
      >
        <HStack justifyContent={'space-between'}>
          <Text fontSize="sm" fontWeight="500" mb={2}>
            Federal Exposure Map
          </Text>
        </HStack>

        <Box height="120px">
          <CanadaMap
            dataset={mapData as Record<string, { color: string }>}
            modalOpen={true}
            isFederalMap={true}
            federalNexusMet={isFedNexusMet}
          />
        </Box>
      </Flex>
    );
  };

  return (
    <Flex
      border={'1px solid var(--gray-100, #CFD0D8)'}
      borderRadius={'4px'}
      p={4}
      overflowX={{ base: 'scroll', md: 'unset' }}
      height={'421px'}
      width={'100%'}
      flexDirection={'column'}
      position={'relative'}
    >
      <Flex justifyContent={'space-between'}>
        <Text fontWeight={500} fontSize={'lg'}>
          {selectedCountry == CountryCodeEnum.CA ? 'Provincial Exposure Map' : 'Exposure Map'}
        </Text>
        <HStack>
          <Box>
            <CountrySelector country={selectedCountry} onCountryChange={handleCountryChange} width="180px" />
          </Box>

          <DialogRoot placement="center" size={'xl'}>
            <DialogBackdrop />
            <DialogTrigger asChild>
              <MdOutlineZoomIn size={'24px'} color={'gray.600'} cursor={'pointer'} />
            </DialogTrigger>
            <DialogContent style={{ maxWidth: '80vw', maxHeight: '80vh' }}>
              <DialogCloseTrigger />
              <DialogBody>
                <Flex mb={4} justifyContent="center" height="70vh">
                  {isMapLoading ? <Skeleton height="400px" /> : getMapByCountry(true)}
                </Flex>
                {!isMapLoading && <MapLegend legendItems={DASHBOARD_LEGEND} />}
              </DialogBody>
            </DialogContent>
          </DialogRoot>
        </HStack>
      </Flex>
      {isMapLoading ? <Skeleton height="350px" mt={2} /> : getMapByCountry()}
      {!isMapLoading && <MapLegend legendItems={DASHBOARD_LEGEND} />}
      {selectedCountry == CountryCodeEnum.CA && !isMapLoading && getFederalExposureMap()}
    </Flex>
  );
};

export default DashboardMap;
