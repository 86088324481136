import { Box, HStack, Icon, Stack, Tabs, Text, useMediaQuery } from '@chakra-ui/react';
import { InfoFilled } from 'components/icons';
import { AccordionItem, AccordionItemContent, AccordionItemTrigger, AccordionRoot } from 'components/ui/accordion';
import { Tooltip } from 'components/ui/tooltip';
import { useMemo } from 'react';
import { DashboardTransactionsChartsData, OrganizationStatsData } from 'types/charts';
import { formatCurrency, formatNumber } from 'utils/utils';

import { useOrgFilingsCharts } from '../hooks/use-org-charts';
import { TotalLiabilityCharts } from './charts/total-liability-charts';
import { TotalSalesCharts } from './charts/total-sales-charts';
import { TotalTaxRemittedCharts } from './charts/total-tax-remitted-charts';
import { TotalTransactionsCountCharts } from './charts/total-transactions-count-charts';

type DashboardMetricTabProps = {
  isLoading: boolean;
  stats: OrganizationStatsData;
  transactionsCharts: DashboardTransactionsChartsData;
};

export const DashboardMetricTab = ({
  stats: { total_sales, total_transactions, outstanding_total_liability, total_tax_remitted },
  transactionsCharts,
  isLoading,
}: DashboardMetricTabProps) => {
  const [isMobile] = useMediaQuery(['(max-width: 1120px)'], {
    ssr: false,
  });
  const tabsData = useMemo(() => {
    return [
      { label: 'Total Sales', value: formatCurrency(total_sales), key: 'total_sales' },
      {
        label: 'Total Transactions',
        value: formatNumber(total_transactions, {
          maximumFractionDigits: 0,
          minimumFractionDigits: 0,
        }),
        key: 'total_transactions',
      },
      {
        label: 'Outstanding Liability',
        value: formatCurrency(outstanding_total_liability),
        key: 'outstanding_liability',
      },
      { label: 'Total Tax Remitted', value: formatCurrency(total_tax_remitted), key: 'total_tax_remitted' },
    ];
  }, [total_sales, total_transactions, outstanding_total_liability, total_tax_remitted]);

  const { filingsCharts, isLoading: isFilingsChartsLoading } = useOrgFilingsCharts({
    enabled: !!transactionsCharts,
  });

  if (isMobile && transactionsCharts) {
    return (
      <AccordionRoot multiple mt={4}>
        {tabsData.map((tab, index) => (
          <AccordionItem key={tab.key} value={tab.key} border="1px" borderColor="gray.100">
            <AccordionItemTrigger flex="1" textAlign="left" background="#EFEFF366" height={'120px'} p={4}>
              <Stack>
                <HStack gap={1}>
                  <Text fontSize="16px">{tab.label}</Text>
                  {index === 2 && (
                    <Tooltip
                      content={
                        'This is the sum of the filing amounts corresponding to all the filings that have not been filed and remitted.'
                      }
                    >
                      <Icon color="#4B5169" fontSize="20px">
                        <InfoFilled />
                      </Icon>
                    </Tooltip>
                  )}
                </HStack>
                <Text fontSize="32px" fontWeight="600" mt={2}>
                  {tab.value}
                </Text>
              </Stack>
            </AccordionItemTrigger>
            <AccordionItemContent pb={4}>
              {index === 0 && (
                <TotalSalesCharts
                  lineChartData={transactionsCharts.sales}
                  barChartData={transactionsCharts.sales_by_state}
                  isLoading={isLoading}
                />
              )}
              {index === 1 && (
                <TotalTransactionsCountCharts
                  lineChartData={transactionsCharts.transactions}
                  barChartData={transactionsCharts.transactions_by_state}
                  isLoading={isLoading}
                />
              )}
              {index === 2 && (
                <TotalLiabilityCharts
                  lineChartData={filingsCharts.outstanding_liability}
                  barChartData={filingsCharts.outstanding_liability_by_state}
                  isLoading={isFilingsChartsLoading}
                />
              )}
              {index === 3 && (
                <TotalTaxRemittedCharts
                  lineChartData={filingsCharts.tax_remitted}
                  barChartData={filingsCharts.tax_remitted_by_state}
                  isLoading={isFilingsChartsLoading}
                />
              )}
            </AccordionItemContent>
          </AccordionItem>
        ))}
      </AccordionRoot>
    );
  }

  return (
    <Tabs.Root defaultValue={'total_sales'} fitted>
      <Tabs.List>
        {tabsData.map((tab, index) => (
          <Tabs.Trigger
            key={tab.key}
            value={tab.key}
            gap={2}
            p={6}
            height="120px"
            minWidth={index === 0 ? '275px' : '180px'}
            flexDirection={'column'}
            alignItems={'flex-start'}
            background="#EFEFF366"
            _selected={{
              borderBottom: '4px solid #4285F4',
              bgColor: 'white',
            }}
            borderWidth={'1px'}
            borderRadius={'4px'}
            borderColor={'gray.100'}
            borderRight={index < tabsData.length - 1 ? 'none' : '1px solid gray.100'}
            justifyContent={'space-between'}
          >
            <HStack gap={1}>
              <Text fontSize={'16px'} color={'#262B47'} fontWeight={'400'}>
                {tab.label}
              </Text>
              {index == 2 && (
                <Tooltip
                  content={
                    'This is the sum of the filing amounts corresponding to all the filings that have not been filed and remitted.'
                  }
                  disabled={index !== 2}
                  contentProps={{
                    textAlign: 'left',
                  }}
                >
                  <Icon color="#4B5169">
                    <InfoFilled />
                  </Icon>
                </Tooltip>
              )}
            </HStack>

            <Text fontSize={'32px'} color="#262B44" fontWeight={'600'} mb={2}>
              {tab.value}
            </Text>
          </Tabs.Trigger>
        ))}
      </Tabs.List>
      <Box borderWidth={'1px'} borderColor={'gray.100'} borderRadius={'4px'} px="4" pb="4">
        <Tabs.Content value={'total_sales'}>
          <TotalSalesCharts
            lineChartData={transactionsCharts?.sales}
            barChartData={transactionsCharts?.sales_by_state}
            isLoading={isLoading}
          />
        </Tabs.Content>
        <Tabs.Content value={'total_transactions'}>
          <TotalTransactionsCountCharts
            lineChartData={transactionsCharts.transactions}
            barChartData={transactionsCharts.transactions_by_state}
            isLoading={isLoading}
          />
        </Tabs.Content>
        <Tabs.Content value={'outstanding_liability'}>
          <TotalLiabilityCharts
            lineChartData={filingsCharts.outstanding_liability}
            barChartData={filingsCharts.outstanding_liability_by_state}
            isLoading={isFilingsChartsLoading}
          />
        </Tabs.Content>
        <Tabs.Content value={'total_tax_remitted'}>
          <TotalTaxRemittedCharts
            lineChartData={filingsCharts.tax_remitted}
            barChartData={filingsCharts.tax_remitted_by_state}
            isLoading={isFilingsChartsLoading}
          />
        </Tabs.Content>
      </Box>
    </Tabs.Root>
  );
};
