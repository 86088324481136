import { useLogoutFunction } from '@propelauth/react';
import { useQuery } from '@tanstack/react-query';
import { getOrganizationById } from 'apis/organizations-apis';
import { useEffect, useState } from 'react';
import { Organization } from 'types/organizations';

import { resetGloballyStoredValues } from './useTrpcWithPropelAuth';

const SELECTED_ORG_KEY = 'selectedOrg';

const useOrg = () => {
  const forceLogout = useLogoutFunction();
  const [selectedOrg, setSelectedOrg] = useState<Organization | null>(() => {
    try {
      const storedOrg = localStorage.getItem(SELECTED_ORG_KEY);
      return storedOrg ? (JSON.parse(storedOrg) as Organization) : null;
    } catch (error) {
      resetGloballyStoredValues();
      localStorage.clear();
      forceLogout(true);
      return null;
    }
  });

  const { data } = useQuery({
    queryKey: ['orgDetails', selectedOrg?.orgId],
    queryFn: async ({ queryKey }) => {
      const [, orgId] = queryKey;
      if (!orgId) {
        return undefined;
      }
      const { data } = await getOrganizationById(orgId);
      return data;
    },
    enabled: !!selectedOrg?.orgId,
  });

  useEffect(() => {
    (() => {
      try {
        const storedOrg = localStorage.getItem(SELECTED_ORG_KEY);
        const parsedOrg = storedOrg ? (JSON.parse(storedOrg) as Organization) : null;
        if (parsedOrg) {
          setSelectedOrg(parsedOrg);
        }
      } catch (error) {
        console.error('Failed to parse organization:', error);
      }
    })();

    const handleStorageChange = () => {
      const newStoredOrg = localStorage.getItem(SELECTED_ORG_KEY);
      const newOrg = newStoredOrg ? (JSON.parse(newStoredOrg) as Organization) : null;
      setSelectedOrg(newOrg);
    };
    window.addEventListener('storage', handleStorageChange);
    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  return selectedOrg
    ? { ...selectedOrg, isTest: data ? data.is_test : selectedOrg.isTest }
    : {
        orgId: '',
        name: '',
        externalId: '',
        isTest: false,
      };
};

const setOrg = (org: Organization) => {
  localStorage.setItem(SELECTED_ORG_KEY, JSON.stringify(org));
  window.dispatchEvent(new Event('storage'));
};

export { type Organization, SELECTED_ORG_KEY, setOrg, useOrg };
