import { caMapProvinces } from 'constants/map-constants';
import { geoCentroid } from 'd3-geo';
import { Annotation, ComposableMap, Geographies, Geography, Marker } from 'react-simple-maps';

import canadaGeoData from './geo-data.json';

const offsets: { [key: string]: [number, number] } = {
  PE: [30, 0],
  NB: [20, 0],
  NS: [30, 10],
  NL: [40, -10],
};

interface CanadaMapProps {
  modalOpen?: boolean;
  federalNexusMet?: boolean;
  isFederalMap?: boolean;
  dataset?: {
    [key: string]: {
      color: string;
    };
  };
}

const CanadaMap = (props: CanadaMapProps) => {
  return (
    <ComposableMap
      projection="geoMercator"
      projectionConfig={{
        scale: 460,
        center: props?.modalOpen ? [-70, 56] : [-50, 56],
        rotate: [20, -5, 10],
        parallels: [5, 5],
      }}
      style={{ width: '100%', height: 'auto' }}
    >
      <Geographies geography={canadaGeoData}>
        {({ geographies }) => (
          <>
            {geographies.map(geo => {
              const cur = caMapProvinces.find(p => p.val === geo.id);
              if (!cur) {
                console.warn(`Province not found for ID: ${geo.id}`);
                return null;
              }
              const { color } =
                props?.isFederalMap && props?.federalNexusMet
                  ? { color: '#FED7D7' }
                  : props?.dataset?.[cur.id] || { color: '#FFFFFF' };

              return (
                <Geography
                  tabIndex={-1}
                  key={geo.rsmKey}
                  geography={geo}
                  fill={color}
                  stroke="#CFD0D8"
                  strokeWidth={0.5}
                  style={{
                    default: { outline: 'none' },
                    hover: { outline: 'none' },
                    pressed: { outline: 'none' },
                  }}
                />
              );
            })}

            {geographies.map(geo => {
              const cur = caMapProvinces.find(p => p.val === geo.id);
              if (!cur) return null;

              const centroid = geoCentroid(geo);
              const hasOffset = cur.id in offsets;

              return (
                <g key={`${geo.rsmKey}-name`}>
                  {hasOffset ? (
                    <Annotation
                      subject={centroid}
                      dx={offsets[cur.id][0]}
                      dy={offsets[cur.id][1]}
                      connectorProps={{
                        stroke: '#000000',
                        strokeWidth: 0.5,
                      }}
                    >
                      <text
                        x={4}
                        fontSize={14}
                        alignmentBaseline="middle"
                        style={{
                          fill: '#000',
                          textShadow: '1px 1px 1px #fff',
                        }}
                      >
                        {cur.id}
                      </text>
                    </Annotation>
                  ) : (
                    <Marker coordinates={centroid}>
                      <text
                        y="2"
                        fontSize={14}
                        textAnchor="middle"
                        style={{
                          fill: '#000',
                          textShadow: '1px 1px 1px #fff',
                        }}
                      >
                        {cur.id}
                      </text>
                    </Marker>
                  )}
                </g>
              );
            })}
          </>
        )}
      </Geographies>
    </ComposableMap>
  );
};

export default CanadaMap;
