import { Box, Flex, Grid, GridItem, HStack, Icon, Skeleton, Stack, Text } from '@chakra-ui/react';
import { useAuthInfo } from '@propelauth/react';
import { useQuery } from '@tanstack/react-query';
import { getInitialImportStatus, TRANSACTION_STATE_KEY } from 'apis/transaction-api';
import AppHeader from 'app/app-header';
import AlertBanner from 'components/alert/alert';
import { InfoFilled } from 'components/icons';
import { Button } from 'components/ui/button';
import { useOrg } from 'hooks/useOrg';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { DashboardAutomation } from './components/dashboard-automation';
import DashboardMap from './components/dashboard-map';
import { DashboardMetricTab } from './components/dashboard-metric-tab';
import { Tasks } from './components/dashboard-tasks';
import { useOrgTransactionsCharts } from './hooks/use-org-charts';
import { useOrgTasksAndStats } from './hooks/use-org-tasks-and-stats';

const Dashboard = () => {
  const navigate = useNavigate();
  const { user } = useAuthInfo();
  const { orgId, isTest } = useOrg();
  const [, setSearchParams] = useSearchParams();

  const { tasks, stats, isLoading } = useOrgTasksAndStats();
  const { transactionsCharts, isLoading: isTransactionsChartsLoading } = useOrgTransactionsCharts();
  const { isPending: isInitialStatusPending, data: statusData } = useQuery({
    queryKey: [TRANSACTION_STATE_KEY, 'TRANSACTION_STATUS', orgId],
    queryFn: async () => {
      const { data } = await getInitialImportStatus(orgId);
      return data;
    },
  });

  if (isLoading) {
    return (
      <Flex flexDirection="column" gap={4} py={6}>
        <Grid templateColumns={{ base: '1fr', md: '1fr', xl: '2fr 1fr' }} gap={4} w="100%">
          <GridItem>
            <Skeleton height="421px" width="full" />
          </GridItem>
          <GridItem>
            <Skeleton height="421px" width="full" />
          </GridItem>
        </Grid>
        <Skeleton height="600px" width="full" />
      </Flex>
    );
  }

  const testOrgBanner = (
    <Flex bg={'secondary.100'} py={'12px'} px={4} align={'center'} flexWrap={'wrap'} gap={2} width={{ xl: '75%' }}>
      <Flex align={{ base: 'center', sm: 'flex-start' }} gap={2} flexGrow={1}>
        <Icon color="secondary.500" w="5" h="5">
          <InfoFilled />
        </Icon>
        <Text color={'gray.800'} textAlign={'justify'} fontSize={'14px'} fontWeight={400}>
          This is a test organization. To switch to a live organization, use the switcher or click the following button
        </Text>
      </Flex>
      <Button
        px={2}
        py={1}
        ml={{ base: 6, sm: 5 }}
        colorPalette="blue"
        onClick={() => {
          if (user?.properties?.partner_id) {
            navigate('/partner-orgs');
          } else {
            setSearchParams({ switchOrg: orgId });
          }
        }}
      >
        Switch Organization
      </Button>
    </Flex>
  );

  const transactionImportAlertContent = (
    <AlertBanner
      width={{ xl: '75%' }}
      message="Transaction import is still in progress, so the numbers may not be the latest. We’ll email you once the
            import is complete."
    />
  );

  return (
    <>
      <HStack gap={4}>
        <AppHeader />
      </HStack>
      <Stack pb={10} gap={4}>
        {isTest && <Box mb={'16px'}>{testOrgBanner}</Box>}
        {!statusData?.initial_import_complete_status && !isInitialStatusPending && transactionImportAlertContent}
        <Grid templateColumns={{ base: '1fr', md: '1fr', xl: '2fr 1fr' }} gap={4} w="100%">
          <GridItem h={'100%'}>
            <DashboardMap />
          </GridItem>
          <GridItem h="100%">
            <Tasks hasTransactions={stats.total_transactions > 0} data={tasks} />
          </GridItem>
        </Grid>
        <DashboardAutomation />
        <DashboardMetricTab
          stats={stats}
          transactionsCharts={transactionsCharts}
          isLoading={isTransactionsChartsLoading}
        />
      </Stack>
    </>
  );
};

export default Dashboard;
