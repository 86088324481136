'use client';

import { createToaster, IconButton, Portal, Spinner, Stack, Toast, Toaster as ChakraToaster } from '@chakra-ui/react';
import { ToastError, ToastInfo, ToastSuccess } from 'components/icons';
import { MdClose } from 'react-icons/md';

export const toaster = createToaster({
  placement: 'top-end',
  pauseOnPageIdle: true,
});

const getToastIcon = (type?: string) => {
  switch (type) {
    case 'loading':
      return <Spinner size="sm" color="blue.solid" />;
    case 'success':
      return <ToastSuccess />;
    case 'info':
      return <ToastInfo />;
    case 'error':
      return <ToastError />;
    default:
      return <Toast.Indicator />;
  }
};
const getToastBgColor = (type?: string) => {
  switch (type) {
    case 'success':
      return 'green.100'; // or any custom color you prefer
    case 'error':
      return 'red.100';
    case 'info':
      return 'secondary.100';
    case 'loading':
      return 'gray.50';
    default:
      return 'white';
  }
};

export const Toaster = () => {
  return (
    <Portal>
      <ChakraToaster toaster={toaster} insetInline={{ mdDown: '4' }}>
        {toast => (
          <Toast.Root width={{ md: 'sm' }} bg={getToastBgColor(toast.type)}>
            {getToastIcon(toast.type)}
            <Stack gap="1" flex="1" maxWidth="100%">
              {toast.title && <Toast.Title>{toast.title}</Toast.Title>}
              {toast.description && <Toast.Description>{toast.description}</Toast.Description>}
            </Stack>
            {toast.action && <Toast.ActionTrigger>{toast.action.label}</Toast.ActionTrigger>}
            {toast.meta?.closable && (
              <Toast.CloseTrigger asChild>
                <IconButton size="md" variant="transparent-with-icon">
                  <MdClose />
                </IconButton>
              </Toast.CloseTrigger>
            )}
          </Toast.Root>
        )}
      </ChakraToaster>
    </Portal>
  );
};
