import { Box, BoxProps } from '@chakra-ui/react';
import React from 'react';

interface TableContainerProps extends BoxProps {
  children?: React.ReactNode;
  isStickyHeader?: boolean;
}

const TableContainer: React.FC<TableContainerProps> = ({ children, isStickyHeader = true, ...rest }) => {
  return (
    <Box
      css={{
        border: '1px solid var(--gray-50, #EFEFF3)',
        borderRadius: '4px',
        px: '12px',
        overflowX: { sm: 'scroll', xl: 'hidden' },
        ...(isStickyHeader && {
          height: '100%',
          '& thead th': {
            position: 'sticky',
            top: '-1px',
            backgroundColor: '#fff',
            zIndex: 1,
          },
        }),
      }}
      {...rest}
    >
      {children}
    </Box>
  );
};

export default TableContainer;
