import { captureException } from '@sentry/react';
import { useQuery } from '@tanstack/react-query';
import { handleApiErrorMessage } from 'apis/api';
import { BILLING_STATE_KEY, getBillingCheckoutHostedPage, getOrgBillingDetails } from 'apis/billing-apis';
import { toaster } from 'components/ui/toaster';
import { useOrg } from 'hooks/useOrg';
import useTracking from 'hooks/useTracking';
import { createContext, PropsWithChildren, useContext } from 'react';
import { BillingPlanEnum } from 'schema/types-schema.d';
import { chargebee } from 'utils/chargebee';

export const usePaywall = () => useContext(PayWallContext);

export const usePayWallProvider = () => {
  const { orgId, isTest } = useOrg();
  const { track } = useTracking();

  const { data, isPending, refetch } = useQuery({
    queryKey: [BILLING_STATE_KEY, 'plan', orgId],
    queryFn: async () => {
      const res = await getOrgBillingDetails(orgId);
      return res;
    },
    select: data => ({
      billingPlan: data.billing_plan,
      isPaidUser: data.billing_plan !== BillingPlanEnum.FREE,
      isSubscriptionPausedOrCanceled: ['CANCELLED', 'PAUSED'].includes(data.subscription_status),
    }),
    enabled: !!orgId,
  });

  const { billingPlan, isPaidUser, isSubscriptionPausedOrCanceled } = data ?? {
    billingPlan: BillingPlanEnum.FREE,
    isPaidUser: false,
    isSubscriptionPausedOrCanceled: false,
  };

  const openPaymentCheckout = async ({ onSuccess, onClose, onError }: PaymentCheckoutParams) => {
    if (isPaidUser) {
      onSuccess && onSuccess();
      return;
    }
    const chargebeeInstance = await chargebee(isTest);
    chargebeeInstance?.openCheckout({
      hostedPage: async () => {
        return await getBillingCheckoutHostedPage(orgId, BillingPlanEnum.GROWTH);
      },
      close: () => {
        onClose && onClose();
        chargebeeInstance?.closeAll();
      },
      step: (currentStep: string) => {
        track('chargebee checkout', { step: currentStep });
      },
      success: () => {
        onSuccess && onSuccess();
        refetch();
      },
      error: (error: Error) => {
        const errorMessage = handleApiErrorMessage(error);
        toaster.create({
          type: 'error',
          title: errorMessage?.title,
          description: errorMessage?.message,
        });
        onError && onError();
        captureException(error);
      },
      layout: 'in_app',
    });
    return chargebeeInstance;
  };

  return { billingPlan, isPaidUser, isPending, openPaymentCheckout, isSubscriptionPausedOrCanceled };
};

type PaywallProviderType = {
  billingPlan: BillingPlanEnum;
  isPaidUser: boolean;
  isSubscriptionPausedOrCanceled: boolean;
  isPending: boolean;
  openPaymentCheckout: (params: PaymentCheckoutParams) => void;
};

type PaymentCheckoutParams = {
  onSuccess?: () => void;
  onError?: () => void;
  onClose?: () => void;
};

const defaultContext = {
  billingPlan: BillingPlanEnum.FREE,
  isPaidUser: false,
  isPending: false,
  isSubscriptionPausedOrCanceled: false,
} as PaywallProviderType;

const PayWallContext = createContext<PaywallProviderType>(defaultContext);

export const PayWallProvider = ({ children }: PropsWithChildren) => {
  const data = usePayWallProvider();
  return <PayWallContext.Provider value={data}>{children}</PayWallContext.Provider>;
};
