import SearchInput from 'components/SearchInput';
import { useDebounce } from 'hooks/useDebounce';
import { UseTableFiltersType } from 'hooks/useTableFilters';
import { useState } from 'react';

type TransactionSearchProps = {
  tableFilters: UseTableFiltersType;
};

const TransactionSearch = ({ tableFilters }: TransactionSearchProps) => {
  const { search_query, setFilters } = tableFilters;
  const [value, setValue] = useState<string>(search_query || '');

  const debouncedRequest = useDebounce(() => {
    setFilters({ search_query: value });
  });

  const handleInputChange = (query: string) => {
    setValue(query);

    debouncedRequest();
  };

  return <SearchInput query={value} setQuery={handleInputChange} styles={{ width: '280px' }} />;
};

export default TransactionSearch;
