import { Icon, List, Stack, Text } from '@chakra-ui/react';
import { Tooltip } from 'components/ui/tooltip';
import { MdInfo } from 'react-icons/md';

export const PhysicalNexusHeaderLevel = () => {
  return (
    <Text fontSize={'20px'} fontWeight="500" display={'inline-flex'} gap={2} alignItems={'center'}>
      Presence
      <Tooltip
        content={
          <Stack>
            <Text color={'white'}>
              {`A "Physical Nexus," in the context of business and taxation, refers to a substantial physical presence or connection that a business entity has in a particular jurisdiction.`}
            </Text>
            <Stack gap={0}>
              <Text color={'white'}>Physical nexus states on our platform include:</Text>
              <List.Root ml={4}>
                <List.Item>Physical stores</List.Item>
                <List.Item>Offices</List.Item>
                <List.Item>Mailing addresses</List.Item>
                <List.Item>Warehouses</List.Item>
                <List.Item>
                  Employees living in the state or having regular presence (include remote employees and contractors)
                </List.Item>
                <List.Item>List of states where inventory is stored</List.Item>
                <List.Item>Event or trade show attendance states</List.Item>
              </List.Root>
            </Stack>
          </Stack>
        }
      >
        <Icon color="#4B5169" fontSize={'20px'} display="inherit">
          <MdInfo />
        </Icon>
      </Tooltip>
    </Text>
  );
};
