import { Box, Flex, HStack, Text } from '@chakra-ui/react';

export type LegendItem = {
  label: string;
  color: string;
  bgColor: string;
};

type MapLegendProps = {
  legendItems: LegendItem[];
};

const MapLegend = ({ legendItems }: MapLegendProps) => {
  return (
    <HStack
      justifyContent={{ base: 'center', sm: 'start', md: 'center' }}
      flexWrap={'wrap'}
      columnGap={6}
      rowGap={{ base: 6, sm: 2 }}
    >
      {legendItems.map((item: LegendItem) => (
        <Flex key={item.label} alignItems="center" mb={{ base: '14px', sm: 0 }} mt={2}>
          <Box
            w={'16px'}
            h={'16px'}
            bg={item.color}
            borderRadius="64px"
            mr={2}
            borderWidth={'1px'}
            borderColor={item.bgColor}
          ></Box>
          <Text fontSize="sm" textAlign="left">
            {item.label}
          </Text>
        </Flex>
      ))}
    </HStack>
  );
};

export default MapLegend;
