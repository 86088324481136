import { Box, Flex, HStack, Input, Skeleton, Text } from '@chakra-ui/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { blankAddressSuggestions } from 'apis/addressses';
import { TRANSACTION_STATE_KEY, updateAddress } from 'apis/transaction-api';
import { AIIcon } from 'components/icons';
import { Button } from 'components/ui/button';
import { Field } from 'components/ui/field';
import { NativeSelectField, NativeSelectRoot } from 'components/ui/native-select';
import {
  PopoverArrow,
  PopoverBody,
  PopoverCloseTrigger,
  PopoverContent,
  PopoverRoot,
  PopoverTrigger,
} from 'components/ui/popover';
import { CountryOptions, USStates } from 'constants/app-constants';
import { useFormik } from 'formik';
import { useHandleNotification } from 'hooks/useApiNotification';
import { useOrg } from 'hooks/useOrg';
import { ChangeEvent, useState } from 'react';
import { AddressInstance, AddressStatus } from 'types/shared-types';
import { replaceNullWithEmptyString, toNormalCase } from 'utils';
import * as Yup from 'yup';

type AddBlankAddressPopoverProps = {
  payload: AddressInstance;
};

const ValidationSchema = Yup.object().shape({
  street_1: Yup.string().optional(),
  street_2: Yup.string().optional(),
  city: Yup.string().required('City is required'),
  state: Yup.string().test('state-required', 'State is required', function (value) {
    const { country } = this.parent;
    if (country === 'US') {
      return !!value;
    }
    return true;
  }),
  postal_code: Yup.string().required('Postal Code is required'),
  country: Yup.string().required('Country is required'),
});

const AddBlankAddressPopover = ({ payload }: AddBlankAddressPopoverProps) => {
  const { orgId } = useOrg();
  const queryClient = useQueryClient();

  const [isSuggestedAddressLoading, setIsSuggestedAddressLoading] = useState(false);
  const [suggestedAddress, setSuggestedAddress] = useState<AddressInstance | null>(null);
  const { handleFailNotification, handleSuccessNotification } = useHandleNotification();
  const [open, setOpen] = useState<boolean>(false);

  const { mutate: updateBlankAddress, isPending } = useMutation({
    mutationFn: (payload: Partial<AddressInstance>[]) => {
      return updateAddress(orgId, payload);
    },
    onSuccess: () => {
      handleSuccessNotification('Address successfully added.');
      queryClient.invalidateQueries({ queryKey: [TRANSACTION_STATE_KEY] });
    },
  });

  const initialFormValues = {
    phone: payload?.phone,
    street_1: payload?.street_1,
    street_2: payload?.street_2,
    city: payload?.city,
    county: payload?.county,
    state: payload?.state,
    postal_code: payload?.postal_code,
    country: payload?.country,
    type: payload?.type,
    status: AddressStatus.BLANK,
    id: payload?.id,
    transaction_id: payload?.transaction_id,
  };

  const { values, setFieldValue, handleChange, handleSubmit, handleReset, touched, isValid, errors, dirty } = useFormik(
    {
      initialValues: suggestedAddress || replaceNullWithEmptyString(initialFormValues),
      enableReinitialize: true,
      validationSchema: ValidationSchema,
      onSubmit: values => {
        updateBlankAddress([values]);
      },
    }
  );

  const handleBlankAddressSuggestion = async (addressId: string) => {
    try {
      setIsSuggestedAddressLoading(true);
      const { data: response } = await blankAddressSuggestions(orgId, addressId);
      const updatedAddress = {
        ...values,
        city: response.city,
        state: response.state,
        postal_code: response.postal_code,
        country: response.country,
      };
      setSuggestedAddress(replaceNullWithEmptyString(updatedAddress));
    } catch (error) {
      handleFailNotification(error);
    } finally {
      setIsSuggestedAddressLoading(false);
    }
  };

  const handleStateChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const selectedStateName = event.target.value;
    const selectedState = USStates.find(state => state.value === selectedStateName);

    setFieldValue('state', selectedState ? selectedState.value : '');
  };

  const handleCountryChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const selectedCountry = event.target.value;
    if (selectedCountry !== 'US') {
      setFieldValue('state', '');
      setFieldValue('county', '');
    }
    setFieldValue('country', selectedCountry);
  };

  const isDisabled =
    (payload?.country === 'US' && values?.country !== 'US') || (payload?.country !== 'US' && values?.country !== 'US');

  return (
    <PopoverRoot open={open} positioning={{ placement: 'bottom-end' }}>
      <PopoverTrigger asChild onClick={() => setOpen(!open)}>
        <Button variant={'outline'} borderWidth={'1px'} width={'6.75rem'}>
          Add Address
        </Button>
      </PopoverTrigger>
      <PopoverContent width={'36.75rem'}>
        <PopoverArrow />
        <PopoverBody>
          <HStack justifyContent={'space-between'} alignItems={'center'} pb={4}>
            <Text fontWeight={'medium'} fontSize={'md'}>
              Ship To
            </Text>
            <Flex alignItems={'center'} gap={4}>
              <AIIcon size="lg" onClick={() => handleBlankAddressSuggestion(payload?.id || '')} cursor={'pointer'} />
              <PopoverCloseTrigger
                style={{ position: 'relative', top: '0' }}
                onClick={() => {
                  handleReset({});
                  setOpen(false);
                }}
              />
            </Flex>
          </HStack>

          <form onSubmit={handleSubmit}>
            <HStack align={'baseline'} gap={4}>
              <Skeleton loading={isSuggestedAddressLoading}>
                <Field
                  label="Country"
                  invalid={!!(errors.country && touched.country)}
                  errorText={String(errors.country)}
                  required
                >
                  <NativeSelectRoot>
                    <NativeSelectField
                      id="country"
                      name="country"
                      value={values.country || ''}
                      onChange={handleCountryChange}
                    >
                      <>
                        {!payload?.country ? (
                          <option value="">Select country</option>
                        ) : (
                          <option value={payload?.country}>
                            {CountryOptions.find(({ value }) => value === payload?.country)?.label}
                          </option>
                        )}
                        {CountryOptions.map(({ label, value }) => (
                          <option key={value} value={value}>
                            {label}
                          </option>
                        ))}
                      </>
                    </NativeSelectField>
                  </NativeSelectRoot>
                </Field>
              </Skeleton>

              <Skeleton loading={isSuggestedAddressLoading}>
                <Field
                  label="State"
                  invalid={!!(errors.state && touched.state)}
                  errorText={String(errors?.state)}
                  required
                >
                  <NativeSelectRoot disabled={isDisabled}>
                    <NativeSelectField
                      id="state_code"
                      name="state_code"
                      value={values.state || ''}
                      onChange={handleStateChange}
                    >
                      {isDisabled ? (
                        <option value="">Select state</option>
                      ) : (
                        <>
                          {!payload?.state || payload?.state === '' ? (
                            <option value="">Select state</option>
                          ) : (
                            <option value={payload?.state}>
                              {USStates.find(option => option.value === payload?.state)?.label}
                            </option>
                          )}
                          {USStates.map(option => (
                            <option key={option.value} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                        </>
                      )}
                    </NativeSelectField>
                  </NativeSelectRoot>
                </Field>
              </Skeleton>
            </HStack>
            <Box height={'16px'} />
            <HStack align={'baseline'} gap={4}>
              <Skeleton loading={isSuggestedAddressLoading}>
                <Field label="City" invalid={!!(errors.city && touched.city)} errorText={String(errors.city)} required>
                  <Input
                    id="city"
                    type="text"
                    name="city"
                    value={toNormalCase(values.city ?? '')}
                    onChange={handleChange}
                    placeholder={payload?.city}
                  />
                </Field>
              </Skeleton>

              <Skeleton loading={isSuggestedAddressLoading}>
                <Field
                  label="Postal Code"
                  invalid={!!(errors.postal_code && touched.postal_code)}
                  errorText={String(errors.postal_code)}
                  required
                >
                  <Input
                    id="postal_code"
                    name="postal_code"
                    value={values.postal_code || ''}
                    onChange={handleChange}
                    placeholder={payload?.postal_code}
                  />
                </Field>
              </Skeleton>
            </HStack>
            <Box height={'16px'} />
            <HStack align={'baseline'} gap={4}>
              <Skeleton loading={isSuggestedAddressLoading}>
                <Field label="Address Line 1">
                  <Input
                    id="street_1"
                    type="text"
                    name="street_1"
                    value={values.street_1 || ''}
                    onChange={handleChange}
                    placeholder={payload?.street_1}
                  />
                </Field>
              </Skeleton>

              <Skeleton loading={isSuggestedAddressLoading}>
                <Field label="Address Line 2">
                  <Input
                    id="street_2"
                    type="text"
                    name="street_2"
                    value={values.street_2 || ''}
                    onChange={handleChange}
                    placeholder={payload?.street_2}
                  />
                </Field>
              </Skeleton>
            </HStack>

            <HStack mt={4} justifyContent={'flex-end'}>
              <Skeleton loading={isSuggestedAddressLoading}>
                <Button
                  type="submit"
                  disabled={!isValid || (!suggestedAddress && !dirty) || isPending}
                  loading={isPending}
                  variant={'solid'}
                  width={'90px'}
                  onClick={() => {
                    handleSubmit();
                    setOpen(false);
                  }}
                >
                  Save
                </Button>
              </Skeleton>
            </HStack>
          </form>
        </PopoverBody>
      </PopoverContent>
    </PopoverRoot>
  );
};

export default AddBlankAddressPopover;
