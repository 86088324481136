import { Flex, Grid, Text } from '@chakra-ui/react';
import { Chart } from 'components/ui/chart';
import { ChartData } from 'types/charts';

type TotalLiabilityChartsProps = {
  lineChartData: ChartData;
  barChartData: ChartData;
  isLoading: boolean;
};

export const TotalLiabilityCharts = ({ lineChartData, barChartData, isLoading }: TotalLiabilityChartsProps) => {
  return (
    <>
      <Flex justify={'space-between'} mb={2}>
        <Text color={'gray.900'} fontWeight="medium" fontSize={'md'}>
          Details
        </Text>
      </Flex>
      <Grid templateColumns={{ base: '1fr', md: '1fr', xl: '1fr 1fr' }} gap={4}>
        <Chart
          type="line"
          height={'373px'}
          heading="Outstanding Liability by Year"
          series={lineChartData?.series}
          options={lineChartData?.options}
          isLoading={isLoading}
        />
        <Chart
          type="bar"
          height={'373px'}
          heading="Outstanding Liability by Jurisdiction"
          series={barChartData?.series}
          options={barChartData?.options}
          isLoading={isLoading}
        />
      </Grid>
    </>
  );
};
