import { usMapStates } from 'constants/map-constants';
import { geoCentroid } from 'd3-geo';
import { Annotation, ComposableMap, Geographies, Geography, Marker } from 'react-simple-maps';

import geography from './geography.json';

interface Offsets {
  [key: string]: [number, number];
}

const offsets: Offsets = {
  VT: [50, -8],
  NH: [34, 2],
  MA: [30, -1],
  RI: [28, 2],
  CT: [35, 10],
  NJ: [34, 1],
  DE: [33, 0],
  MD: [47, 10],
  DC: [49, 21],
};

type USMapProps = {
  dataset: Record<string, { color: string }>;
};

const USMap = (props: USMapProps) => {
  return (
    <ComposableMap
      projection="geoAlbersUsa"
      style={{ width: '100%', height: '100%' }}
      projectionConfig={{
        scale: 1300,
      }}
    >
      <Geographies geography={geography}>
        {({ geographies }) => (
          <>
            {geographies.map(geo => {
              const cur = usMapStates.find(s => s.val === geo.id) ?? { id: '' };
              const { color } = props?.dataset?.[cur.id] || { color: '#FFFFFF' };

              return (
                <Geography
                  tabIndex={-1}
                  key={geo.rsmKey}
                  stroke="#CFD0D8"
                  strokeWidth="1"
                  geography={geo}
                  fill={color}
                  style={{
                    default: { outline: 'none' },
                    hover: { outline: 'none' },
                    pressed: { outline: 'none' },
                  }}
                />
              );
            })}
            {geographies.map(geo => {
              const centroid = geoCentroid(geo);
              const cur = usMapStates.find(s => s.val === geo.id);
              return (
                <g key={geo.rsmKey + '-name'}>
                  {cur &&
                    centroid[0] > -160 &&
                    centroid[0] < -67 &&
                    (Object.keys(offsets).indexOf(cur.id) === -1 ? (
                      <Marker coordinates={centroid}>
                        <text y="2" fontSize={14} textAnchor="middle">
                          {cur.id}
                        </text>
                      </Marker>
                    ) : (
                      <Annotation
                        connectorProps={{
                          stroke: '#000000',
                          strokeWidth: 1,
                        }}
                        subject={centroid}
                        dx={offsets[cur.id][0]}
                        dy={offsets[cur.id][1]}
                      >
                        <text x={4} fontSize={14} alignmentBaseline="middle">
                          {cur.id}
                        </text>
                      </Annotation>
                    ))}
                </g>
              );
            })}
          </>
        )}
      </Geographies>
    </ComposableMap>
  );
};
export default USMap;
