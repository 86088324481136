import { useQuery } from '@tanstack/react-query';
import api from 'apis/api';
import { USStatesByCode } from 'constants/app-constants';
import { useOrg } from 'hooks/useOrg';
import { OrganizationChartsStateData } from 'types/charts';
import { CountryCodeEnum } from 'types/shared-types';
import { fillMissingChartData, getBarChartOptions, getLineChartOptions } from 'utils/charts';

const getStateLabel = (stateCode: string) => {
  return USStatesByCode[stateCode]?.label || 'Unknown';
};

const useOrgTransactionsCharts = () => {
  const { orgId } = useOrg();

  const { data: transactionsCharts, isLoading } = useQuery({
    queryKey: ['charts', 'transactions', orgId],
    queryFn: async () => {
      const { data } = await api.get('/v1/dashboard/charts/transactions', {
        params: {
          country: CountryCodeEnum.US,
        },
        headers: {
          'x-organization-id': orgId,
        },
      });
      return data;
    },
    select: data => {
      const charts = {
        sales: {
          series: [
            {
              name: 'Sales',
              data: fillMissingChartData(data.sales).map(({ value, date }) => ({
                x: date,
                y: Number(value),
              })),
            },
          ],
          options: getLineChartOptions('datetime'),
        },
        transactions: {
          series: [
            {
              name: 'Transactions',
              data: fillMissingChartData(data.transactions).map(({ value, date }) => ({
                x: date,
                y: Number(value),
              })),
            },
          ],
          options: getLineChartOptions('datetime', false),
        },
        sales_by_state: {
          series: [
            {
              name: 'Sales By State',
              data: data.sales_by_state.map(({ value }: OrganizationChartsStateData) => value),
            },
          ],
          options: getBarChartOptions(
            data.sales_by_state.map(({ state }: OrganizationChartsStateData) => getStateLabel(state))
          ),
        },
        transactions_by_state: {
          series: [
            {
              name: 'Transactions By State',
              data: data.transactions_by_state.map(({ value }: OrganizationChartsStateData) => value),
            },
          ],
          options: getBarChartOptions(
            data.transactions_by_state.map(({ state }: OrganizationChartsStateData) => getStateLabel(state)),
            false
          ),
        },
      };
      return charts;
    },
    enabled: !!orgId,
    refetchOnWindowFocus: false,
  });
  return {
    transactionsCharts: transactionsCharts ?? {
      sales: {
        series: [],
        options: {},
      },
      transactions: {
        series: [],
        options: {},
      },
      sales_by_state: {
        series: [],
        options: {},
      },
      transactions_by_state: {
        series: [],
        options: {},
      },
    },
    isLoading,
  };
};

const useOrgFilingsCharts = ({ enabled }: { enabled: boolean }) => {
  const { orgId } = useOrg();

  const { data: filingsCharts, isLoading } = useQuery({
    queryKey: ['charts', 'filings', orgId],
    queryFn: async () => {
      const { data } = await api.get('/v1/dashboard/charts/filings', {
        params: {
          country: CountryCodeEnum.US,
        },
        headers: {
          'x-organization-id': orgId,
        },
      });
      return data;
    },
    select: data => {
      const charts = {
        outstanding_liability: {
          series: [
            {
              name: 'Outstanding Liability',
              data: fillMissingChartData(data.outstanding_liability).map(({ value, date }) => ({
                x: date,
                y: Number(value),
              })),
            },
          ],
          options: getLineChartOptions('datetime'),
        },
        tax_remitted: {
          series: [
            {
              name: 'Total Tax Remitted',
              data: fillMissingChartData(data.tax_remitted).map(({ value, date }) => ({
                x: date,
                y: Number(value),
              })),
            },
          ],
          options: getLineChartOptions('datetime'),
        },
        outstanding_liability_by_state: {
          series: [
            {
              name: 'Outstanding Liability By State',
              data: data.outstanding_liability_by_state.map(({ value }: OrganizationChartsStateData) => Number(value)),
            },
          ],
          options: getBarChartOptions(
            data.outstanding_liability_by_state.map(({ state }: OrganizationChartsStateData) => getStateLabel(state))
          ),
        },
        tax_remitted_by_state: {
          series: [
            {
              name: 'Total Tax Remitted By State',
              data: data.tax_remitted_by_state.map(({ value }: OrganizationChartsStateData) => Number(value)),
            },
          ],
          options: getBarChartOptions(
            data.tax_remitted_by_state.map(({ state }: OrganizationChartsStateData) => getStateLabel(state))
          ),
        },
      };
      return charts;
    },
    enabled: !!orgId && enabled,
    refetchOnWindowFocus: false,
  });
  return {
    filingsCharts: filingsCharts ?? {
      outstanding_liability: {
        series: [],
        options: {},
      },
      tax_remitted: {
        series: [],
        options: {},
      },
      outstanding_liability_by_state: {
        series: [],
        options: {},
      },
      tax_remitted_by_state: {
        series: [],
        options: {},
      },
    },
    isLoading,
  };
};

export { useOrgFilingsCharts, useOrgTransactionsCharts };
